// ------------------------------------------------------------
// .l-mainのパディングトップをヘッダーの高さと同じにする
// ------------------------------------------------------------
(function($) {

  var timer = false;

  function setHeight() {
    var headerHeight = $('.l-header').outerHeight();
    $('.l-main').css('padding-top', headerHeight + 'px');
  }

  $(function() {
    setHeight();
  });

  $(window).on('resize', function() {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      setHeight();
    }, 50);
  });

  $(window).on('load', function() {
    setHeight();
  });

})(jQuery);
