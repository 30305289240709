// ------------------------------------------------------------
// ロールオーバー
// ------------------------------------------------------------
(function($) {
  $(function() {
    $('.imgover').not('[src*="' + '_ov' + '."]').hover(function() {
      if ($(window).width() > 767) {
        $(this).stop().attr('src', $(this).attr('src').replace(/\.(gif|jpe?g|png)$/, '_ov.$1'));
      }
    }, function() {
      if ($(window).width() > 767) {
        $(this).stop().attr('src', $(this).attr('src').replace(/_ov\.(gif|jpe?g|png)$/, '.$1'));
      }
    });
  });
})(jQuery);
