// ------------------------------------------------------------
// ドロワーメニュー
// ------------------------------------------------------------
(function($) {

  var flag = 0;
  var setScroll = 0;
  var scroll = '';
  var headerHeight = '';
  var windowHeight = '';


  function getValue() {
    // スクロール量
    scroll = $(window).scrollTop();
    // ヘッダーの高さ
    headerHeight = $('.l-header').outerHeight();
    // ウィンドウの高さ
    windowHeight = $(window).height();
  }

  function drawer() {
    getValue();
    if (flag) {
      flag = 0;
      $("body").css({
        'overflow': 'visible',
        'position': 'relative',
        'top': 'auto',
        'width': 'auto',
      });
      $("html, body").scrollTop(scrollSet);
    } else {
      flag = 1;
      scrollSet = scroll;
      $("body").css({
        'overflow': 'hidden',
        'position': 'fixed',
        'top': -scroll,
        'width': '100%',
      });
    }
    // ナビゲーションのトップをヘッダーのトップ
    $('.p-spNav-wrapper').css('top', headerHeight + 'px');
    $('.p-spNav-wrapper').css('height', windowHeight - headerHeight + 'px');
    // barsとcloseの切り替え
    $('.l-header_btn-inner').toggleClass('is-open');
    // overlayをフェード
    // $('.p-overlay').fadeToggle('slow');
    // 開閉
    $('#js-drawerMenuContent').slideToggle();
  }


  $('#js-drawerMenuTrigger').on('click', function() {
    drawer();
  });
})(jQuery);


// ------------------------------------------------------------
// トップページのロゴの状態
// ------------------------------------------------------------
(function($) {

  var timer = false;
  var winWidth = '';
  var winScroll = '';
  var home = false;

  $(window).on('resize', function() {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      winWidth = $(window).width();
    }, 50);
  });

  $(window).on('scroll', function() {
    if (home) {
      winScroll = $(window).scrollTop();
      if (winWidth > 767) {
        if (winScroll > 600) {
          $('.l-header_title').fadeIn();
        }
        if (winScroll <= 600) {
          $('.l-header_title').fadeOut();
        }
      }
    }
  });

})(jQuery);

// ------------------------------------------------------------
// 横スクロール時のヘッダーの挙動
// ------------------------------------------------------------
(function($) {

  $(window).on('scroll', function() {
    $('.l-header').css('left', -window.pageXOffset + 'px');
  });

})(jQuery);
