// ------------------------------------------------------------
// アンカー処理
// ------------------------------------------------------------
(function($) {

  // click
  $('a[href^="#"]').on('click', function() {
    var headerHight = 0;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - headerHight;
    $('html, body').animate({
      scrollTop: position
    }, 700);
    return false;
  });

  // Ready
  $(function() {
    if (location.hash) {
      $('html, body').scrollTop(0);
    }
  });

  // Load
  $(window).on('load', function() {
    if (location.hash) {
      $('html, body').scrollTop(0);

      setTimeout(function() {
        var headerHeight = $('.l-header').outerHeight();
        var anchor = $(location.hash).offset().top - headerHeight;
        $('html, body').animate({
          scrollTop: anchor
        }, 500);
      }, 200);

    }
  });

})(jQuery);
