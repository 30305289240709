// ----------------------------------------
// フォームまわりのjs
// ----------------------------------------
$(function() {
  // ----------------------------------------
  // Pikadayインスタンス化（カレンダーのUI）
  // ----------------------------------------
  (function enablePikaday() {
    // ※'YY/MM'のフォーマットで指定すること
    var datesToDisable = ['12/29', '12/30', '12/31', '01/01', '01/02', '01/03'];

    var targets = document.querySelectorAll('[data-pikaday]');
    Array.prototype.forEach.call(targets, function (element) {
      new Pikaday({
        field: element,
        minDate: new Date(),
        format: 'YYYY年MM月DD日',
        disableDayFn: function (datetime) {
          return $.inArray(moment(datetime).format('MM/DD'), datesToDisable) !== -1;
        },
        i18n: {
          previousMonth: '前月',
          nextMonth: '翌月',
          months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
          weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
        },
      });
    });
  })();

  if ($('body.contact').length > 0 || $('body.recruit_contact').length > 0) {
    $('body').on(
      'blur',
      'input, textarea',
      function() {
        if ($(this).prev().hasClass('Error') && $(this).val()) {
          $(this).prev().remove();
        }
      }
    );
    $('body').on(
      'change',
      'input[type="radio"]',
      function() {
        if ($(this).parents('.c-form_radio').find('.Error').hasClass('Error')) {
          $(this).parents('.c-form_radio').find('.Error').remove();
        }
      }
    );
  }
});
