// ------------------------------------------------------------
// ドロップダウンメニュー
// ------------------------------------------------------------
(function($) {
  $(function() {
    $('#js-dropMenuTrigger01').on('mouseover', function() {
      $('#js-dropMenuContent01').addClass('is-open');
    });
    $('#js-dropMenuTrigger01').on('mouseout', function() {
      $('#js-dropMenuContent01').removeClass('is-open');
    });
    $('#js-dropMenuTrigger02').on('mouseover', function() {
      $('#js-dropMenuContent02').addClass('is-open');
    });
    $('#js-dropMenuTrigger02').on('mouseout', function() {
      $('#js-dropMenuContent02').removeClass('is-open');
    });
  });
})(jQuery);
