// ------------------------------------------------------------
// 画像の切り替え
// ------------------------------------------------------------
(function($) {

  var scroll_timer = false;
  var replaceWidth = 768;

  function imgSize() {
    var $setElem = $('.switch'),
      pcName = '_pc',
      spName = '_sp';

    $setElem.each(function() {
      var $this = $(this);
      var w = window.innerWidth ? window.innerWidth : $(window).width();
      var windowWidth = parseInt(w);
      if (windowWidth >= replaceWidth) {
        $this.attr('src', $this.attr('src').replace(spName, pcName)).fadeIn('fast');
      } else if (windowWidth < replaceWidth) {
        $this.attr('src', $this.attr('src').replace(pcName, spName)).fadeIn('fast');
      }
    });
  }

  $(function() {
    imgSize();
  });

  $(window).resize(function() {
    if (scroll_timer !== false) {
      clearTimeout(scroll_timer);
    }
    scroll_timer = setTimeout(function() {
      imgSize();
    }, 10);
  });

})(jQuery);
