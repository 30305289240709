// ------------------------------------------------------------
// PC時のページトップへ戻るボタン
// ------------------------------------------------------------
(function($) {

  // Load
  $(window).on('load', function() {
    // 画面の高さ
    var windowHeight = $(window).height();
    // スクロール量
    var scrollValue = $(window).scrollTop();

    if (scrollValue > 600) {
      $('#backToTop').fadeIn('normal');
    } else {
      $('#backToTop').fadeOut('fast');
    }
    backtotop(windowHeight);
  });

  function backtotop(windowHeight) {
    $(window).on('scroll', function() {
      var scrollValue = $(window).scrollTop();
      if (scrollValue > 600) {
        $('#backToTop').fadeIn('normal');
      } else {
        $('#backToTop').fadeOut('fast');
      }
    });
  }

})(jQuery);
